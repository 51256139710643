<template>
  <div>
    <v-container>
      <v-row dense>
          <v-col class="login_form rounder pa-5">
            <h3 class="text-center mb-5">PRIJAVA</h3>
            <form @submit.prevent="submit" :id="form_id">
              <div class="form_inner">
                <div class="input_holder">
                  <v-text-field
                    v-model="uname"
                    :error-messages="usernameErrors"
                    :label="t_username"
                    v-eager
                    required
                    solo
                    @input="$v.uname.$touch()"
                    @blur="$v.uname.$touch()"
                  ></v-text-field>
                </div>
                <div class="input_holder border_top">
                  <v-text-field
                    v-model="pass"
                    :error-messages="passwordErrors"
                    :label="t_password"
                    v-eager
                    required
                    solo
                    type="password"
                    @input="$v.pass.$touch()"
                    @blur="$v.pass.$touch()"
                  ></v-text-field>
                </div>
              </div>
              <v-btn class="submit_btn mt-5" color="black" type="submit" :disabled="submitStatus === 'PENDING'">
                Prijavite se
              </v-btn>
              <div class="text-center pt-2">
                <p class="typo__p" v-if="submitStatus === 'OK'">Prijavljeni ste</p>
                <p class="typo__p error-txt" v-if="submitStatus === 'ERROR'">Molimo Vas popunite formu pravilno</p>
                <p class="typo__p" v-if="submitStatus === 'PENDING'">Šaljem...</p>
                <p class="typo__p error-txt" v-if="loginError !== ''">{{ loginError }}</p>
              </div>
            </form>
            <div class="text-center forgot_pass">
              <p class="ubundu_b"><router-link :to="'recovery'" class="black_color">Zaboravili ste lozinku?</router-link></p>
            </div>
          </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="modal.dialog"
      max-width="320"
    >
      <v-card>
        <v-card-title class="headline">{{ modal.title }}</v-card-title>
        <v-card-text>
            {{ modal.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="close_modal"
          >
            U redu
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import store from "@/store"
//import { fokus_fix } from '../assets/js/script.js'
import { validationMixin } from 'vuelidate'
import { required, maxLength, minLength, alphaNum } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      form_id: 'login',
      uname: '',
      pass: '',
      form_enabled: false,
      t_username: 'Korisničko ime',
      t_password: 'Lozinka',
      window: {
          w: 0,
          h: 0
      }
    }
  },
  mixins: [validationMixin],
  validations: {
    uname: {
      required,
      alphaNum,
      minLength: minLength(6),
      maxLength: maxLength(30)
    },
    pass: {
      required,
      minLength: minLength(6),
    }
  },
  computed: {
    ...mapGetters({
      submitStatus: 'forms/submitStatus',
      loginError: 'user/getLoginError',
      modal: 'forms/modal',
    }),
    usernameErrors () {
      const errors = []
      if (!this.$v.uname.$dirty) return errors
      !this.$v.uname.maxLength && errors.push('Korisničko ime može sadržati najviše 30 znakova')
      !this.$v.uname.minLength && errors.push('Korisničko ime mora sadržati najmanje 6 znakova')
      !this.$v.uname.alphaNum && errors.push('Dozvoljeni su samo brojke i slova')
      !this.$v.uname.required && errors.push('Korisničko ime je obavezno')
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.pass.$dirty) return errors
      !this.$v.pass.required && errors.push('Lozinka je obavezna')
      !this.$v.pass.minLength && errors.push('Lozinka mora imati najmanje 6 znakova')
      return errors
    },
  },
  methods: {
    clearError(){
      this.$store.commit('user/clearLoginError')
    },
    close_modal(){
        this.$store.commit('forms/setModal', { dialog:false }, { root: true })
    },
    handleResize() {
        this.window.w = window.innerWidth;
        this.window.h = window.innerHeight;
    },
    submit () {
      this.clearError()
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$store.dispatch('forms/changeSubmitStatus','ERROR')
      } else {
        this.$store.dispatch('forms/changeSubmitStatus','PENDING')
        this.$store.dispatch('user/login_form', {
          form: this.form_id,
          data: {
            username: this.uname,
            password: this.pass
          }
        })
      }
    },
  },
  mounted(){
    //fokus_fix()
  },
  destroyed(){
      this.$store.dispatch('forms/changeSubmitStatus',null)
      this.$store.dispatch('forms/changeSubmitError',null)
  },
  beforeRouteEnter (to, from, next) {
        if(typeof to.redirectedFrom !== 'undefined'){
            if(to.redirectedFrom.includes('ver_change')){
                setTimeout(
                    ()=>{
                        store.dispatch('emit', {
                            end:'forms/modal',
                            call: 'ver_change',
                            parameters: {
                                hash:to.params.hash
                            },
                            response:"{\"status\": 200}",
                            status:1
                        }, { root: true })
                    },2000
                )
            }else if(to.redirectedFrom.includes('gen_pass')){
                setTimeout(
                    ()=>{
                        store.dispatch('emit', {
                            end:'forms/modal',
                            call: 'gen_pass',
                            parameters: {
                                hash:to.params.hash
                            },
                            response:"{\"status\": 200}",
                            status:1
                        }, { root: true })
                    },2000
                )
            }
        }
        next()
    },

};
</script>
<style scoped>
  .login_form{
      background-color: rgba(242,223,215,.79);
    }
  .slide{
    height:calc(100vh - 64px);
    width:100%;
    position:relative;
}
.image_holder{
    height:calc(100vh - 64px);
    width:100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
@media screen and (max-width: 750px) {
    .slide{
        height:calc(100vh - 128px);
    }
    .image_holder{
        height:calc(100vh - 128px);
    }
}
.container_first, .container_last{
    position:relative;
}
.container_last::before, .container_first::after{
    content:"";
    width:100%;
    position:absolute;
    top:50px;
    border-top:1px solid rgb(209, 95, 158);
    height:0;
    /*box-shadow:0 0 6px rgba(209, 95, 158,.8);*/
}
.container_first::after{
    top:unset;
    bottom:50px;
}
</style>
